<template>
  <div
    class="flex flex-row items-center py-1"
    :class="{
      'justify-center': sizing === 'slogan',
    }"
  >
    <img
      data-not-lazy
      v-lazy-load :data-src="logoSrc"
      crossorigin="anonymous"
      alt=""
      :class="{
        'h-10 w-10': sizing === 'footer' || sizing === 'registration',
        'h-[60px] md:h-[150px]': sizing === 'slogan',
      }"
    />
    <div
      class="text-green-500 pl-2"
      :class="{
        'text-[2rem] pt-1': sizing === 'footer' || sizing === 'registration',
        'text-xl pt-1 font-bold': sizing === 'header',
        'text-5xl pt-2 font-bold md:text-8xl': sizing === 'slogan',
      }"
    >
      {{ /*TODO: this should be removed once czech site is fully deployed*/}}
      <template v-if="isCzechia">SUPER</template>
      <template v-else>
        {{ $t("branding.logo.part1") }}
      </template>
    </div>
    <div
      class="text-primary-dark"
      :class="{
        'text-[2rem] pt-1': sizing === 'footer' || sizing === 'registration',
        'text-xl pt-1 font-bold': sizing === 'header',
        'text-5xl pt-2 font-bold md:text-8xl': sizing === 'slogan',
      }"
    >
      {{ /*TODO: this should be removed once czech site is fully deployed*/}}
      <template v-if="isCzechia">PROFIK </template>
      <template v-else>
        {{ $t("branding.logo.part2") }}
      </template>
    </div>
  </div>
</template>

<script>
import JoszakiLogo from "~/assets/joszaki-head.svg";
import JoszakiLogoXmas from "~/assets/joszaki-head-xmas.svg";

import { generatePreloadLinks } from "~/helpers/files";

export default {
  name: "JoszakiLogo",
  props: {
    sizing: {
      type: String,
      default: "header",
      validator: (value) =>
        ["header", "footer", "slogan", "registration"].includes(value),
    },
  },
  head() {
    return {
      link: generatePreloadLinks([
        { as: "image", type: "image/svg+xml", href: this.logoSrc },
      ]),
    };
  },
  computed: {
    isCzechia() {
      return this.$i18n.locale === "cs";
    },
    logoSrc() {
      if (new Date().getMonth() > 9) {
        return JoszakiLogoXmas;
      } else {
        return JoszakiLogo;
      }
    },
  },
};
</script>
