var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row items-center py-1",class:{
    'justify-center': _vm.sizing === 'slogan',
  }},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],class:{
      'h-10 w-10': _vm.sizing === 'footer' || _vm.sizing === 'registration',
      'h-[60px] md:h-[150px]': _vm.sizing === 'slogan',
    },attrs:{"data-not-lazy":"","data-src":_vm.logoSrc,"crossorigin":"anonymous","alt":""}}),_c('div',{staticClass:"text-green-500 pl-2",class:{
      'text-[2rem] pt-1': _vm.sizing === 'footer' || _vm.sizing === 'registration',
      'text-xl pt-1 font-bold': _vm.sizing === 'header',
      'text-5xl pt-2 font-bold md:text-8xl': _vm.sizing === 'slogan',
    }},[_vm._v(" "+_vm._s(/*TODO: this should be removed once czech site is fully deployed*/)+" "),(_vm.isCzechia)?[_vm._v("SUPER")]:[_vm._v(" "+_vm._s(_vm.$t("branding.logo.part1"))+" ")]],2),_c('div',{staticClass:"text-primary-dark",class:{
      'text-[2rem] pt-1': _vm.sizing === 'footer' || _vm.sizing === 'registration',
      'text-xl pt-1 font-bold': _vm.sizing === 'header',
      'text-5xl pt-2 font-bold md:text-8xl': _vm.sizing === 'slogan',
    }},[_vm._v(" "+_vm._s(/*TODO: this should be removed once czech site is fully deployed*/)+" "),(_vm.isCzechia)?[_vm._v("PROFIK ")]:[_vm._v(" "+_vm._s(_vm.$t("branding.logo.part2"))+" ")]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }