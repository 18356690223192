<template>
  <div :class="{ [$route.query?.referrer ?? '']: true }">
    <b-navbar
      class="!sticky z-1000 top-0 max-h-[60px] border-primary border-b-[3px]"
      :mobile-burger="false"
    >
      <template #brand>
        <b-navbar-item class="!cursor-default">
          <JoszakiLogo />
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="pb-16">
      <Nuxt />
    </div>
  </div>
</template>

<script>
import { defineComponent, useMeta } from "@nuxtjs/composition-api";
import { useDefaultHead } from "~/composables/defaultHead";
import JoszakiLogo from "~/components/_refactored/img/JoszakiLogo.vue";

export default defineComponent({
  components: { JoszakiLogo },
  setup() {
    useMeta(() => useDefaultHead());
  },
  head: {},
});
</script>
